
import Header from '../Header'
import Footer from '../Footer'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import './contact.sass'

const Contact = () => {

    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault()

        emailjs.sendForm(
            'service_msparks',
            'template_c2kqs17',
            form.current,
            'WbNlNvKllHzPKFz0-'
        ).then(
            () => {
                //success handler - need to hide form and show success message
                alert('success')
                window.location.reload(false)
            },
            () => {
                //fail handler - need to stylize form for error handling
                alert('fail')
                window.location.reload(false)
            }
        )
    }

    return (

        <main className='contact'>
            <Header />
            <div className='title'>
                <section className='container'>
                    <h2 className='title-header'>Contact Me</h2>
                    <p className='title-txt'>Thank you for your interest in Mary Sparks. Please fill out the form below if you have any questions or need more information.</p>
                </section>
            </div>
            <section className='container'>
                <main>

                    <div className='contact__container'>
                        <form className='form' ref={form} onSubmit={sendEmail}>
                            <input type='hidden' name='subject' val='Mary Sparks Contact Form' maxLength="80" />
                            <label className='form__label form__label-name'>
                                Name
                                <input placeholder='Name' type='text' name='name' className='form__input' maxLength="80" required />
                            </label>
                            <label className='form__label form__label-company'>
                                Company
                                <input placeholder='Company' type='text' name='company' maxLength="80" className='form__input' required />
                            </label>
                            <label className='form__label form__label-phone'>
                                Phone
                                <input placeholder='Phone' type='tel' name='phone' maxLength="15" className='form__input' required />
                            </label>
                            <label className='form__label form__label-email'>
                                Email
                                <input placeholder='mary.sparks1.ms@gmail.com' type='email' name='email' maxLength="80" className='form__input' required />
                            </label>
                            <label className='form__label form__label-message'>
                                Message
                                <textarea placeholder='Message' name='message' maxLength="2000" rows="6" className='form__input' required></textarea>
                            </label>
                            <input type='submit' className='form__submit' value='send' />
                        </form>

                        <div className='contact__info'>
                            <strong>Find Me Online:</strong>
                            <a href='https://www.facebook.com/mary.sparks.315' target="_blank" rel="noreferrer" className='contact__link contact__link--facebook'>Facebook Link</a>
                            <a href='https://www.youtube.com/@marysparks2975' target="_blank" rel="noreferrer" className='contact__link contact__link--youtube'>Youtube Link</a>
                            <a href='https://open.spotify.com/artist/359k2BY7y74tldQ9kgIEr3' target="_blank" rel="noreferrer" className='contact__link contact__link--spotify'>Spotify Link</a>
                            <a href='https://marysparks.bandcamp.com/music' target="_blank" rel="noreferrer" className='contact__link contact__link--bandcamp'>BandCamp Link</a>
                            <a href='https://soundcloud.com/user-868727553' target="_blank" rel="noreferrer" className='contact__link contact__link--soundcloud'>Sound Cloud Link</a>


                        </div>
                    </div>
                </main>
            </section>
            <Footer />
        </main>


    )
}

export default Contact