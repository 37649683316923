
import Header from '../Header'
import Footer from '../Footer'
import './albums.sass'

const Albums = () => {

    return (

        <main className='albums'>
            <Header />
            <div className='title'>
                <section className='container'>
                    <h1 className='title-header'>Albums</h1>
                    <p className='title-txt'>Listen and become bewitched to a magical oasis ...</p>
                </section>
            </div>
            <section className='container'>
                <main>


                    <section className='music__container'>
                        <article className='music' id='album1'>
                            <div className='music__left'>
                                <iframe src="https://www.youtube.com/embed/videoseries?list=PLfeZAoGscizzj247iRTceSOwvBUUiVrx5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className='music__video'></iframe>
                            </div>
                            <div className='music__right'>
                                <h1 className='music__title'>Snapshots of the Muse</h1>
                                <ol className='music__list'>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=s6D_qq2XwmY&list=PLfeZAoGscizzj247iRTceSOwvBUUiVrx5&index=1' target='_blank' rel="noreferrer">Ritual of Bells</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=HSWhelwXQRI&list=PLfeZAoGscizzj247iRTceSOwvBUUiVrx5&index=3' target='_blank' rel="noreferrer">Out of Darkness</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=byOcJMzQnw0&list=PLfeZAoGscizzj247iRTceSOwvBUUiVrx5&index=5' target='_blank' rel="noreferrer">Reflections</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=n70UotF0Tk4&list=PLfeZAoGscizzj247iRTceSOwvBUUiVrx5&index=2' target='_blank' rel="noreferrer">Calling the Tribe</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=HO32BIfUriQ&list=PLfeZAoGscizzj247iRTceSOwvBUUiVrx5&index=4' target='_blank' rel="noreferrer">Rythm of the Stars</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=30uFFVfN8YQ&list=PLfeZAoGscizzj247iRTceSOwvBUUiVrx5&index=6' target='_blank' rel="noreferrer">Mysteria</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=NYNZUMGBT8g&list=PLfeZAoGscizzj247iRTceSOwvBUUiVrx5&index=7' target='_blank' rel="noreferrer">Time</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=wzkg8e7lF4U&list=PLfeZAoGscizzj247iRTceSOwvBUUiVrx5&index=8' target='_blank' rel="noreferrer">Ancient Design</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=l4Rbth_diFA&list=PLfeZAoGscizzj247iRTceSOwvBUUiVrx5&index=9' target='_blank' rel="noreferrer">Prayer for the Waiting</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=ANitjkrM0LA&list=PLfeZAoGscizzj247iRTceSOwvBUUiVrx5&index=11' target='_blank' rel="noreferrer">A Gentle Rain</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=24P97L0CqDA&list=PLfeZAoGscizzj247iRTceSOwvBUUiVrx5&index=10' target='_blank' rel="noreferrer">Into the Mist</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=ZhwYncowXVg&list=PLfeZAoGscizzj247iRTceSOwvBUUiVrx5&index=12' target='_blank' rel="noreferrer">Ageless Child</a></li>
                                </ol>
                                <a href='https://marysparks.bandcamp.com/album/snapshots-of-the-muse' className='music__link' target='_blank' rel="noreferrer">Buy Now</a>
                            </div>
                        </article>

                        <article className='music' id='album3'>
                            <div className='music__left'>
                                <iframe src="https://www.youtube.com/embed/videoseries?list=PLfeZAoGscizwpO19XrB6jDhj3A8RhpSyn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" className='music__video' allowfullscreen></iframe>
                            </div>
                            <div className='music__right'>
                                <h1 className='music__title'>Hammer Dulcimer & Piano Improvisations</h1>
                                <ol className='music__list'>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=G2tbs7ty2EQ&list=PLfeZAoGscizwpO19XrB6jDhj3A8RhpSyn&index=1' target='_blank' rel="noreferrer">Follow Me</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=aVbFlHTEBek&list=PLfeZAoGscizwpO19XrB6jDhj3A8RhpSyn&index=2' target='_blank' rel="noreferrer">Ebb and Flow</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=i-hexyMmxiM&list=PLfeZAoGscizwpO19XrB6jDhj3A8RhpSyn&index=3' target='_blank' rel="noreferrer">celebration in E</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=H4cJjhw9fy0&list=PLfeZAoGscizwpO19XrB6jDhj3A8RhpSyn&index=4' target='_blank' rel="noreferrer">Hammer Dance</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=HdSm2swwzvc&list=PLfeZAoGscizwpO19XrB6jDhj3A8RhpSyn&index=5' target='_blank' rel="noreferrer">Glad we Met</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=-d1ocRKV5Jk&list=PLfeZAoGscizwpO19XrB6jDhj3A8RhpSyn&index=6' target='_blank' rel="noreferrer">So long, for now</a></li>
                                </ol>
                                <a href='https://marysparks.bandcamp.com/album/improvisations' className='music__link' target='_blank' rel="noreferrer">Buy Now</a>
                            </div>
                        </article>
                        <article className='music' id='album4'>
                            <div className='music__left'>
                                <iframe src="https://www.youtube.com/embed/videoseries?list=OLAK5uy_nftG9xoZ0BpONc9QBqxJVGte4jaRJE7sY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" className='music__video' allowfullscreen></iframe>
                            </div>
                            <div className='music__right'>
                                <h1 className='music__title'>Barefoot Dancer</h1>
                                <ol className='music__list'>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=mOcgvu4ptvg&list=OLAK5uy_nftG9xoZ0BpONc9QBqxJVGte4jaRJE7sY' target='_blank' rel="noreferrer">Spirit of the Dawn</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=l5LfV6_rPco' target='_blank' rel="noreferrer">Metamorphosis</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=N6WehBF9Fds' target='_blank' rel="noreferrer">Dream Garden</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=FJ0Jt9ZpZr0' target='_blank' rel="noreferrer">Tango del Alma</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=wjkjk1Pinss' target='_blank' rel="noreferrer">Rain</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=Wzsgsntk0v0' target='_blank' rel="noreferrer">Flight of Enchantment</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=U9uVCUC1A2c' target='_blank' rel="noreferrer">Barefoot Dancer</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=absN92DQSbY' target='_blank' rel="noreferrer">Transcendentally Yours</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=mno9TJhg2lc' target='_blank' rel="noreferrer">Spirit of the Night</a></li>
                                </ol>
                                <a href='https://marysparks.bandcamp.com/album/barefoot-dancer' className='music__link' target='_blank' rel="noreferrer">Buy Now</a>
                            </div>
                        </article>


                        <article className='music' id='album2'>
                            <div className='music__left'>
                                <iframe src="https://www.youtube.com/embed/BY7lrVY8dro" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" className='music__video' allowfullscreen></iframe>
                            </div>
                            <div className='music__right'>
                                <h1 className='music__title'>Space Medicine</h1>
                                <ol className='music__list'>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=BY7lrVY8dro&list=PLfeZAoGscizw1qFlXLnFGJIXYPslGa5Ut&index=1' target='_blank' rel="noreferrer">A minor Excursion</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=dEnEG8fHTvc&list=PLfeZAoGscizw1qFlXLnFGJIXYPslGa5Ut&index=2' target='_blank' rel="noreferrer">Frolic</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=h9zZzRzvHRw&list=PLfeZAoGscizw1qFlXLnFGJIXYPslGa5Ut&index=3' target='_blank' rel="noreferrer">Scorpio Sun</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=YYgreQfalx8&list=PLfeZAoGscizw1qFlXLnFGJIXYPslGa5Ut&index=4' target='_blank' rel="noreferrer">The Inner Temple</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=ylcobGYRfmU&list=PLfeZAoGscizw1qFlXLnFGJIXYPslGa5Ut&index=5' target='_blank' rel="noreferrer">Nature's Dance</a></li>
                                    <li className='music__list-item'><a href='https://www.youtube.com/watch?v=oTzZm9IhY0c&list=PLfeZAoGscizw1qFlXLnFGJIXYPslGa5Ut&index=6' target='_blank' rel="noreferrer">Blue Minor</a></li>
                                </ol>
                                <a href='https://marysparks.bandcamp.com/album/space-medicine' className='music__link' target='_blank' rel="noreferrer">Buy Now</a>
                            </div>
                        </article>

                        <hr className='hr hr--dash hr--v' />
                        <aside className='collab'>
                            <h2 className='collab__title'>Featured Collaborations </h2>
                            <p className='collab__txt'>Music made with friends, and here to share...</p>

                            <div className='grid grid--col2'>
                                <div className='grid__col'>
                                    <iframe className='collab__music' title='featured collabs 1' scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/386041790&color=%236e277c&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                                </div>
                                <div className='grid__col'>
                                    <iframe className='collab__music' title='featured collabs 2' scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/182887153&color=%236e277c&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                                </div>
                            </div>
                            <div className='grid grid--col2'>
                                <div className='grid__col'>
                                    <iframe className='collab__music' title='featured collabs 3' scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/284391890&color=%236e277c&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                                </div>
                                <div className='grid__col'>
                                    <iframe className='collab__music' title='featured collabs 4' src="https://open.spotify.com/embed/track/456rm5cMVSS8CbUFUrBBok?utm_source=generator" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                                </div>
                            </div>
                            <div className='grid grid--col2'>
                                <div className='grid__col'>
                                    <iframe width="560" height="315" title='featured collabs 5' src="https://www.youtube.com/embed/n16vBrDamE0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div className='grid__col'>
                                    <iframe width="560" height="315" title='featured collabs 5' src="https://www.youtube.com/embed/nHpYX8hK_q4?start=287" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div className='grid grid--col2'>
                                <div className='grid__col'>
                                    <iframe width="560" height="315" title='featured collabs 5' src="https://www.youtube.com/embed/3aWXCAnC8eg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div className='grid__col'>
                                </div>
                            </div>
                        </aside>


                    </section>
                </main>
            </section>
            <Footer />
        </main>


    )
}

export default Albums