
import Header from '../Header'
import Footer from '../Footer'
import './live.sass'

const Tour = () => {

    return (

        <main className='live'>
            <Header />
            <div className='title'>
                <section className='container'>
                    <h2 className='title-header'>Live</h2>
                    <p className='title-txt'>Enjoy the tunes live...</p>
                </section>
            </div>
            <section className='container'>
                <main>

                    <section className='live__videos'>

                        <iframe src="https://www.youtube.com/embed/5cuAGLD-VTA" title="YouTube video player 22" class="live__video-vid" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                        <iframe src="https://www.youtube.com/embed/OuFRcZuHqZ0" title="YouTube video player 21" class="live__video-vid" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>



                        <iframe src="https://www.youtube.com/embed/l61Xo1cvz7I" title="YouTube video player 3" class="live__video-vid" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>


                        <iframe src="https://www.youtube.com/embed/LkLvc1XQcgo" title="YouTube video player 6" class="live__video-vid" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                        <iframe src="https://www.youtube.com/embed/PZdVrlwf_l0" title="YouTube video player 7" class="live__video-vid" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                        <iframe src="https://www.youtube.com/embed/RcPknGuhOzA" title="YouTube video player 8" class="live__video-vid" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                        <iframe src="https://www.youtube.com/embed/4LzRwLnmcDA" title="YouTube video player 9" class="live__video-vid" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>



                        <iframe src="https://www.youtube.com/embed/SelL98dMvXo" title="YouTube video player 20" class="live__video-vid" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                        <iframe src="https://www.youtube.com/embed/nCjcUtSWRRQ" title="YouTube video player 10" class="live__video-vid" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>



                        <iframe src="https://www.youtube.com/embed/Yf-rUd04sj4" title="YouTube video player" class="live__video-vid" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                        <iframe src="https://www.youtube.com/embed/ts2GZPgTJrY" title="YouTube video player 2" class="live__video-vid" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>



                    </section>
                    <hr className='hr hr--dash hr--y' />
                    <aside className='collab collab--live'>
                        <h2 className='collab__title'>Live Drum Performances  </h2>
                        <p className='collab__txt'>Dance to the beat of the drums...</p>

                        <div className='grid grid--col2'>

                            <div className='grid__col'>
                                <iframe src="https://www.youtube.com/embed/rHZPaaMtIvM" title="YouTube video player 14" class="collab__music" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className='grid__col'>
                                <iframe src="https://www.youtube.com/embed/UqzbWI4f-z4" title="YouTube video player 16" class="collab__music" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>

                        </div>
                        <div className='grid grid--col2'>
                            <div className='grid__col'>
                                <iframe src="https://www.youtube.com/embed/crFYlM09fc8" title="YouTube video player 13" class="collab__music" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className='grid__col'>
                                <iframe src="https://www.youtube.com/embed/mi-naBEwGLg" title="YouTube video player 17" class="collab__music" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='grid grid--col2'>


                            <div className='grid__col'>
                                <iframe src="https://www.youtube.com/embed/GfNcGhTrwcY" title="YouTube video player 18" class="collab__music" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className='grid__col'>
                                <iframe src="https://www.youtube.com/embed/9jOgV5ke3DU" title="YouTube video player 19" class="collab__music" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='grid grid--col2'>


                            <div className='grid__col'>
                                <iframe src="https://www.youtube.com/embed/GUZFU81AGkQ" title="YouTube video player 15" class="collab__music" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <div className='grid__col'>
                            </div>
                        </div>
                    </aside>
                </main>
            </section>
            <Footer />
        </main>


    )
}

export default Tour