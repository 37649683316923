import './footer.sass'

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='container'>

                <div className='company'>

                    <div className='footer__socials'>
                        <a href='https://www.facebook.com/mary.sparks.315' target="_blank" rel="noreferrer" className='footer__socials-link footer__socials-link--facebook'>Facebook Link</a>
                        <a href='https://www.youtube.com/@marysparks2975' target="_blank" rel="noreferrer" className='footer__socials-link footer__socials-link--youtube'>Youtube Link</a>
                        <a href='https://open.spotify.com/artist/359k2BY7y74tldQ9kgIEr3' target="_blank" rel="noreferrer" className='footer__socials-link footer__socials-link--spotify'>Spotify Link</a>
                        <a href='https://marysparks.bandcamp.com/music' target="_blank" rel="noreferrer" className='footer__socials-link footer__socials-link--bandcamp'>BandCamp Link</a>
                        <a href='https://soundcloud.com/user-868727553' target="_blank" rel="noreferrer" className='footer__socials-link footer__socials-link--soundcloud'>Sound Cloud Link</a>
                    </div>
                    <p className='footer__disclaimer'>
                        © 2011–{new Date().getFullYear()} Mary Sparks All Rights Reserved.
                    </p>
                </div>

            </div>
        </footer>
    )
}

export default Footer