
import Header from '../Header'
import Footer from '../Footer'
import './store.sass'

const Store = () => {

    return (

        <main className='store'>
            <Header />
            <div className='title'>
                <section className='container'>
                    <h2 className='title-header'>Store</h2>
                    <p className='title-txt'>Pick up the Merch...</p>
                </section>
            </div>
            <section className='container'>
                <main>
                    
                    <h1 className='store__header'>Thanks for checking in, but currently there are no store items. Check back later</h1>
                    {/* <section className='cart__list'>
                        <article className='cart'>
                            <time className='cart__time' datetime="2023-03-8 20:00">March 8, 2023</time>
                            <h1 className='cart__title'>Tour Name Here</h1>
                            <a href='#' className='cart__link' target='_blank'>Buy Tickets</a>
                        </article>
                        <article className='cart'>
                            <time className='cart__time' datetime="2023-03-8 20:00">March 8, 2023</time>
                            <h1 className='cart__title'>Tour Name Here</h1>
                            <a href='#' className='cart__link' target='_blank'>Buy Tickets</a>
                        </article>
                        <article className='cart'>
                            <time className='cart__time' datetime="2023-03-8 20:00">March 8, 2023</time>
                            <h1 className='cart__title'>Tour Name Here</h1>
                            <a href='#' className='cart__link' target='_blank'>Buy Tickets</a>
                        </article>
                        <article className='cart'>
                            <time className='cart__time' datetime="2023-03-8 20:00">March 8, 2023</time>
                            <h1 className='cart__title'>Tour Name Here</h1>
                            <a href='#' className='cart__link' target='_blank'>Buy Tickets</a>
                        </article>
                        <article className='cart'>
                            <time className='cart__time' datetime="2023-03-8 20:00">March 8, 2023</time>
                            <h1 className='cart__title'>Tour Name Here</h1>
                            <a href='#' className='cart__link' target='_blank'>Buy Tickets</a>
                        </article>
                        <article className='cart'>
                            <time className='cart__time' datetime="2023-03-8 20:00">March 8, 2023</time>
                            <h1 className='cart__title'>Tour Name Here</h1>
                            <a href='#' className='cart__link' target='_blank'>Buy Tickets</a>
                        </article>
                        
                    </section> */}
                </main>
            </section>
            <Footer />
        </main>


    )
}

export default Store