
import Header from '../Header'
import Footer from '../Footer'
import Me from '../../img/me.jpg'
import Me2 from '../../img/ms-live.jpg'
import './about.sass'

const About = () => {

    return (

        <main className='about'>
            <Header />
            <div className='title'>
                <section className='container'>
                    <h2 className='title-header'>about Me</h2>
                    <p className='title-txt'>multi-instrumentalist best known for her hammered dulcimer improvisations</p>
                </section>
            </div>
            <section className='container'>
                <main>

                    <div className='about__container'>
                        <img src={Me} alt="Mary Sparks playing the Hammer Dulcimer" className='about__img' />
                        {/* <h1 className='about__header'></h1> */}
                        <br /><br />
                        <p>In a research paper I did years ago on music therapy, I explored its use during pregnancy and childbirth. I began with: </p>
                        <blockquote><p>Music has been referred to as the universal language for its ability to break through cultural, language, educational, and emotional barriers. <strong>Music is legendary</strong> for soothing the savage beast, for conveying joy and sorrow, and as a means to lull a cranky infant to sleep. From the earliest chants and crudest instruments to today's digitized electronic soundscapes, music has played a significant role in all aspects of human activity and enterprise.</p></blockquote>


                        <p>That understanding has been a part of all things musical during my life, and led to my discovery and eventual embrace of the hammered dulcimer as the instrument through which my muse travels most unencumbered by the grind of daily life and chaos in the world. Having been born to musical parents, it was only natural that my brother and I would follow in their footsteps. Dad was an accomplished steel guitar player and teacher who owned and operated two music schools. Mom was one of his most promising students, who performed for years with a trio that entertained the troops at Jefferson Barracks Army Base in Missouri. My brother and I began our journeys with piano lessons.</p>

                        <p>We started when I was six, and for the following six and a half years I both loved and hated those piano lessons. I loved learning and being able to make music on the old upright my grandma had given to us, so that my brother and I had one on which to practice. I hated being called upon to play for every visiting relative and friend, and I especially feared the yearly recital. I didn't know what stage fright was, but I sure did suffer from it.</p>


                        <p className='space'>About five years in, I decided that what I really wanted to do was play the drums. So, I started arranging stacks of thick telephone books (yeah, that's where we looked up phone numbers back then) onto various pieces of furniture…to create a workable drum set. I used No 2 pencils until I got my first pair of drum sticks. There wasn't much sound, but I knew from the first paradiddle that drumming was in my future.</p>

                        <div className='grid grid--col2 space'>
                            <div className='grid__col'>
                                <p>At twelve and a half years of age, and after a year of whining and moaning, my parents let me quit piano lessons. For my thirteenth birthday, they bought me a set of real drums. Well, they were as real as one could get for $49.99 at the Grandpa Pidgeon's store…which sold everything from WW II bayonets and canteens, to clothing, to fishing gear, to musical instruments, and everything in between. But that little set of drums may just as well have been the finest Ludwig or Pearl kit. They were just the beginning of a lifetime being "the drummer." I ended up playing in every sort of band, from country to classic rock and blues to all originals…and at every sort of venue, from nearly empty bars to festivals and big-stage events. </p>
                            </div>
                            <div className='grid__col'>
                                <p>Along the way, I taught myself how to play the guitar and other stringed instruments, including the violin and cello (neither of which I ever came close to mastering). And I continued plunking away on the piano, whenever one was nearby. All wind instruments eluded me, so I didn't even waste my time on them. I concluded that if an instrument could be plucked, strummed, or struck I could play it.  Eventually, I met up with the hammered dulcimer. I first heard one being played when in my mid-twenties, learned what it was called when in my thirties, and got my first one when in my late forties. It was then that I realized that everything I'd done musically, up to that point, had led me straight to the dulcimer. </p>
                            </div>
                        </div>



                        <p className='space'>I spent every available moment figuring out how to make music on this new instrument…part of which demanded becoming proficient at tuning it…which was and still is nearly an hour-long process. Over time I hit fewer and fewer "bad" notes, and began creating interesting patterns and tunes. The more time passed, the more comfortable I got improvising and letting my muse flow freely and without any particular structure. While this was happening, I moved to Asheville NC with my partner and landed a job working at Moog Music. </p>



                        <blockquote><p><strong>Robert Moog</strong>, the inventor of the first analog synthesizer <small>(which forever altered our musical soundscapes)</small>, was my boss. Unbelievable!</p></blockquote>



                        <p>Not only did I learn every phase of building his latest keyboard synth, I also became the technician who tuned and calibrated every Mini-Voyager II before it went out the door. And while there, I had the great pleasure of meeting and talking with Herbie Hancock. He gave our whole crew tickets to that evening's show…where we were all dazzled by his solo on the synthesizer we'd prepped for him that afternoon. What a treat! I didn't stay with Moog Music for very long, but working there was an experience I'll never forget. </p>

                        <p className='space'>I moved back to the Midwest for a while and it was during that time that I recorded my first CD, "Snapshots of the Muse." Jon Valley, a musician I'd met after responding to his ad looking for a drummer, was intrigued by the hammered dulcimer and offered to record me. Go figure. I improvised and composed on the fly for dozens of hours over a period of several weeks, while Jon captured it all. Final selections were then mastered at a professional studio in St. Louis, MO. To launch that CD, I had the great fortune of having friends and family pull together and make it an event…a stage performance that drew an audience of almost two hundred.  </p>
                        <blockquote><p>Not a huge crowd, but to me it was like performing at <strong>Carnegie Hall</strong>!</p></blockquote>

                        <img src={Me2} alt="Mary Sparks playing the Hammer Dulcimer live" className='about__img-alt' />



                        <p>Even so, I missed Asheville and moved back. Once settled I totally embraced busking. A whole new world opened up. I quickly became known around town as "the dulcimer lady." (On a side note, I was actually going to adopt that as exclusively my own, until I found out a woman out on the west coast had already patented the title.) Back to busking. Even though it's great to be on a big stage, or even a small one, playing music on a sidewalk with a steady stream of tourists and townspeople passing by, often gathering to watch and listen…was indescribable. The lives I was able to touch, the people I got to interact with, and the relative anonymity of just playing my instrument, head down and concentrating, was pure joy. The tip jar was good to me, as well. And the other street performers I met and collaborated with was equally joy-inducing…for us and for listeners.</p>

                        <p className='space'>One day the director of a local radio station was hurrying back to his office after his lunch break. I was playing in front of a big iron…literally a giant statue of a clothes iron…outside of the Flatiron Building (a landmark structure in Asheville). It was a busking hotspot. He stopped and listened for a few moments, took one of my business cards, and then moved on. A few days later he called and asked if I would come in for an interview and to play a couple of tunes. It was humbling to hear him describe his walk back from lunch that day. To paraphrase, "It was like I started moving in slow motion as soon as I heard that sound…so intriguing and beautiful…it stayed with me all the way back to my office." </p>

                        <p className='space'>Two particularly memorable events happened during my years in NC. I often joined in with the Friday night drum circle…one of the city's many tourist attractions and a great way to start the weekend. On June 23, 2007, The Smashing Pumpkins did a show at the Orange Peel, which was named one of the top five music venues in America by Rolling Stone magazine in 2008. A manager of the band extended an invitation to the core members of the drum circle to open for them, and I was asked to join in on my hammered dulcimer.</p>

                        <blockquote><p>What a night that was…opening for <strong>The Smashing Pumpkins</strong> to a sold-out crowd! </p></blockquote>

                        <p>And not long after that, on October 7, 2007 Mickey Hart's Global Drum Project came to the Orange Peel. Again, the drum circle was asked to perform before the show. Since the "Project" was a multi-faceted and stunning audio/visual experience that needed no opening act, we played outside near the entrance as people were arriving. After this groups' jaw-dropping performance, we were all invited to sit in and jam with them. And these were four of the most famous percussionists from around the world…Mickey Hart, Zakir Hussain, Sikiru Adepoju, and Giovanni Hidalgo. Talk about cool! </p>

                        <p className='space'>It was around that same time that I finished recording my second CD, "Barefoot Dancer," and formed my band, Space Medicine, which became known locally for its intriguing improvisations. We existed in various configurations, depending on who was sitting in, and performed every Friday night at the Kava Bar for almost three years. More good fortune led me to collaborating with Rob Lenfestey, keyboardist extraordinaire. We recorded for hours at his home studio, over several days, playing whatever came to us. The CD "Improvisations" is a compilation of my favorites from those sessions. </p>

                        <p className='space'>Shortly after that I moved to Seattle, where I stepped right in as the new drummer in my brother's band. As a teenager, Bob had diverted his talents to the electric guitar just before I switched to the drums, and we ending up playing in various bands together during our twenties and thirties. With both of us on the west coast we were, once again, sharing a stage. At one of our gigs about six years ago (before that band split up), I was approached by an all-original band, Silver Lining, and soon became their drummer and back-up singer. I've managed to find a musical family no matter where the road has led me, and I'm deeply appreciative of every person, place and experience that has influenced both what I listen to and what I create.</p>

                        <p>My musical explorations continue to this day, as I go with a flow that sometimes makes little sense, but is always an adventure. I will continue being "the drummer" for as long as I can haul a kit around, set them up, and still rock the house. However, the instrument that kept me so captivated for the past twenty-five years has, again, got my full attention as I put my past musings out for all to hear and begin finding new ones to share.</p>

                        <p>Having experienced many highlights along my path, the music I find on the hammered dulcimer is the real journey. </p>

                        <hr className='hr hr--o hr--dash' />

                        <p>What follows, for whoever is interested, is the story of when I became interested in the hammered dulcimer, and the uncanny chain of events that resulted in my being able to find just the right one and purchase it. </p>


                        <p>I grew up in the Midwest and back in the seventies I went with several friends to an amusement park in southern Missouri called, Silver Dollar City. It was one of the places my parents had taken the family during the sixties, when my brother and I were just kids. Back then there were dirt roads, taffy and fudge making, blacksmiths, assorted Ozark crafts, and a big cave to explore…everything we loved!</p>

                        <p className='space'>So, when my friends and I went we were in our mid-twenties, and I couldn't believe my eyes. It had become an Ozark version of Six Flags and every other major amusement park now in every major city. The redeeming factor, once inside, was that it had maintained a lot of its old appeal and attractions. The very first thing I noticed was this incredible music coming from somewhere nearby. I immediately drifted over to the little gazebo in which sat four musicians playing bluegrass tunes. Not really a fan of bluegrass, what drew me to their music was the instrument in the middle of the stage…like nothing I'd ever seen or heard. </p>

                        <blockquote><p>The sound coming from it was <strong>mesmerizing</strong> and had me completely in its grips. </p></blockquote>


                        <p >My friends eventually pulled me away to go do fun stuff like go on rides and overeat. I never found out that day what the instrument was, but the thought of it and its beautiful sound stayed with me.</p>

                        <p className='space'>Twenty-five years later, I uprooted my life and moved to North Carolina…the reasons for which are irrelevant here…but almost as soon as I got there, I developed this burning desire to find out what that instrument was, especially since I'd heard it referred to by locals as some sort of dulcimer. I had to find out more. Later, while glancing through the local paper I saw there was a woman that very evening giving a demonstration of the hammered dulcimer and other mountain instruments at the local library. I dragged my best friend along, and even though the audience was mostly kids and their parents, it didn't matter. I felt kind of like a kid, myself. Afterwards, she invited them all up to check out the instruments. Of course, I let the little ones go first, but I was chomping at the bit to get my hands on a pair of those hammers to check out that incredible instrument. I finally got my turn, and I did not want to stop!</p>

                        <p>After all the kids were done and gone, I approached this gracious woman (whose name has sadly escaped me) and we got to talking. She asked if I played, and I said, "Not this, not yet, but I'm going to!" Before it was all said and done, she agreed to rent me an irreplaceable Masterworks hammered dulcimer, because she was so enjoying her new handmade Jerry Read Smith five-octave chromatic dulcimer, and was planning on renting her Masterworks to students. This act of kindness and trust was shocking because I was a complete stranger and wasn't going to take lessons from anyone. And it was so cool that she saw something in me that made her know it was a good thing to help me out.</p>

                        <p>Long story a little shorter…she let me keep it for two months and only charged one month's rent, until I was able to purchase my own…which was made possible by a small inheritance from an uncle. It was truly meant to be!</p>

                        <p className='space'>For the next couple of decades my life was all about becoming acquainted with this amazing instrument…one that is as old as any instrument ever made. It's dated back to over 2000 years ago, originating in Persia, from which it spread out over the centuries into other countries, which used different wood and materials and gave it different names. The version I have, while recently crafted, originated in Ireland and/or England and the first hammered dulcimer came to the US in the early 1900s, somewhere in the upper east coast area of the US. And it's anybody's guess as to how it and the lap dulcimer are even related or how they became such staples in the music one finds in parts of the Appalachian Mountains. </p>

                        <p>With that said, I do hope that you not only enjoy my music, but that you allow it to take you on whatever journey gives you the most peace and comfort. </p>

                        <br />
                        <p>Namaste'</p>
                    </div>

                    {/* <section className='about__blog'>
                        <article className='blog'>
                            <time className='blog__time' datetime="2023-03-8 20:00">March 8, 2023</time>
                            <h1 className='blog__title'>Blog Title Name can be as long as this text or a bit longer</h1>
                            <p className='blog__txt'>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
                            <p className='blog__txt'>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec non enim in turpis pulvinar facilisis. Ut felis. Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus</p>
                        </article>
                        <article className='blog'>
                            <time className='blog__time' datetime="2023-03-14 20:00">March 3, 2023</time>
                            <h1 className='blog__title'>Blog Title Name can be as long as this text or a bit longer</h1>
                            <p className='blog__txt'>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
                            <p className='blog__txt'>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec non enim in turpis pulvinar facilisis. Ut felis. Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus</p>
                        </article>
                        <article className='blog'>
                            <time className='blog__time' datetime="2023-02-18 20:00">February 18, 2023</time>
                            <h1 className='blog__title'>Blog Title Name can be as long as this text or a bit longer</h1>
                            <p className='blog__txt'>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
                            <p className='blog__txt'>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec non enim in turpis pulvinar facilisis. Ut felis. Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus</p>
                        </article>
                    </section> */}
                </main>
            </section>
            <Footer />
        </main>


    )
}

export default About