import { Routes, Route } from 'react-router-dom'
import Home from './components/Home'
import Contact from './components/Contact'
import About from './components/About'
import Live from './components/Live'
import Albums from './components/Albums'
import Store from './components/Store'
import './App.sass';
function App() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="/live" element={<Live />} />
      <Route path="/albums" element={<Albums />} />
      <Route path="/store" element={<Store />} />
    </Routes>
  )
}

export default App
