import { Link } from 'react-router-dom'
import Header from '../Header'
import Footer from '../Footer'
import album1 from '../../img/album1.jpg';
import album2 from '../../img/album2.jpg';
import album3 from '../../img/album3.jpg';
import album4 from '../../img/album4.jpg';



import './home.sass'

const Home = () => {
  

   
    return (

        <main className='home'>
            <Header />
            <section className='slider'>
                <div className='container'>
                    
                </div>
            </section>
            <section className='about'>
                <div className='container'>
                    <h2 className='about__title'>About</h2>
                    <p>I taught myself how to play the guitar and other stringed instruments, including the violin and cello (neither of which I ever came close to mastering). And I continued plunking away on the piano, whenever one was nearby. All wind instruments eluded me, so I didn't even waste my time on them. I concluded that if an instrument could be plucked, strummed, or struck I could play it. Eventually, I met up with the hammered dulcimer. I first heard one being played when in my mid-twenties, learned what it was called when in my thirties, and got my first one when in my late forties. It was then that I realized that everything I'd done musically, up to that point, had led me straight to the dulcimer.....<Link to='/about' title="learn more about me"><u>Read More</u></Link></p>
                </div>
            </section>
            <section className='quote'>
                <div className='container'>
                    <h5 className='quote__lyrics'>deep beneath the rolling waves in labyrinths of coral caves</h5>
                    <h5 className='quote__lyrics-artist'>-pink floyd </h5>
                </div>
            </section>
            <section className='albums'>
                <div className='container'>
                    <h2 className='albums__title'>Albums</h2>
                    <div className='grid grid--col4 grid--centered grid--space'>
                        <div className='grid__col'>
                            <Link to='/albums' className="albums__link" title="View All Albums">
                                <img src={album1} alt="album1" className='grid__img' />
                            </Link>
                            {/* <p><a href='https://www.youtube.com/watch?v=mOcgvu4ptvg&list=OLAK5uy_nftG9xoZ0BpONc9QBqxJVGte4jaRJE7sY' rel="noreferrer" target="_blank" className='albums__btn'>listen</a>  <a href='https://www.amazon.com/gp/browse.html?node=5174&ref_=nav_em__dm_cds_vinyl_0_2_3_8' rel="noreferrer" target="_blank" className='albums__btn albums__btn--drk'>buy now</a></p> */}
                        </div>
                        <div className='grid__col'>
                            <Link to='/albums' className="albums__link" title="View All Albums">
                                <img src={album2} alt="album1" className='grid__img' />
                            </Link>
                            {/* <p><a href='https://www.youtube.com/watch?v=mOcgvu4ptvg&list=OLAK5uy_nftG9xoZ0BpONc9QBqxJVGte4jaRJE7sY' rel="noreferrer" target="_blank" className='albums__btn'>listen</a> <a href='https://www.amazon.com/gp/browse.html?node=5174&ref_=nav_em__dm_cds_vinyl_0_2_3_8' rel="noreferrer" target="_blank" className='albums__btn albums__btn--drk'>buy now</a></p> */}
                        </div>
                        <div className='grid__col'>
                            <Link to='/albums' className="albums__link" title="View All Albums">
                                <img src={album3} alt="album1" className='grid__img' />
                            </Link>
                            {/* <p><a href='https://www.youtube.com/watch?v=mOcgvu4ptvg&list=OLAK5uy_nftG9xoZ0BpONc9QBqxJVGte4jaRJE7sY' rel="noreferrer" target="_blank" className='albums__btn'>listen</a>  <a href='https://www.amazon.com/gp/browse.html?node=5174&ref_=nav_em__dm_cds_vinyl_0_2_3_8' rel="noreferrer" target="_blank" className='albums__btn albums__btn--drk'>buy now</a></p> */}
                        </div>
                        <div className='grid__col'>
                            <Link to='/albums' className="albums__link" title="View All Albums">
                                <img src={album4} alt="album1" className='grid__img' />
                            </Link>
                            {/* <p><a href='https://www.youtube.com/watch?v=mOcgvu4ptvg&list=OLAK5uy_nftG9xoZ0BpONc9QBqxJVGte4jaRJE7sY' rel="noreferrer" target="_blank" className='albums__btn'>listen</a>  <a href='https://www.amazon.com/gp/browse.html?node=5174&ref_=nav_em__dm_cds_vinyl_0_2_3_8' rel="noreferrer" target="_blank" className='albums__btn albums__btn--drk'>buy now</a></p> */}
                        </div>
                    </div>
                    <Link to='/albums' className="albums__btn albums__btn--main" title="View All Albums">
                        View All
                    </Link>
                </div>
            </section>
            <section className='quote'>
                <div className='container'>
                    <h5 className='quote__lyrics'>because we separate like ripples on a blank shore in rainbows.</h5>
                    <h5 className='quote__lyrics-artist'>-radiohead </h5>
                </div>
            </section>
            {/* <section className='store'>
                <div className='container'>
                    <h2 className='store__title'>Featured Store Items</h2>
                    <div className='grid grid--col2 grid--centered grid--space'>
                        <div className='grid__col'>
                            <Link to='/store' className="albums__link" title="View All Store Items">
                                <img src="https://picsum.photos/400/283" alt="store item" className='grid__img' />
                            </Link>
                            <h4 className='store__item-title'>Store Item 1</h4>
                            <p className='store__item-desc'>Store Item description explaining what this item is, maybe dimensions of features of this item.</p>
                            <p><a href='STORELINK' rel="noreferrer" target="_blank" className='store__btn'>buy</a></p>
                        </div>
                        <div className='grid__col'>
                            <Link to='/albums' className="albums__link" title="View All Albums">
                            <img src="https://picsum.photos/400/281" alt="store item" className='grid__img' />
                            </Link>
                            <h4 className='store__item-title'>Store Item 2</h4>
                            <p className='store__item-desc'>Store Item description explaining what this item is, maybe dimensions of features of this item.</p>
                            <p><a href='STORELINK' rel="noreferrer" target="_blank" className='store__btn'>buy</a></p>
                        </div>
                    </div>
                    <Link to='/store' className="store__btn store__btn--main" title="View All Store Items">
                        View All Store Items
                    </Link>
                </div>
            </section> */}
            <Footer />
        </main>

    )
}

export default Home