import { NavLink } from 'react-router-dom'
import { useState } from "react"
import './header.sass'
// import logo from '../../assets/images/logo.png';

const Header = () => {
    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        setActive(!isActive);
    };
    return (
        <header className='header'>
            <nav className={isActive ? "nav" : "nav nav--active"}>
                <div className='nav__container'>
                    <button type='button' className={isActive ? "nav__mobile nav__mobile-toggle" : "nav__mobile nav__mobile-toggle nav__mobile--active"} onClick={handleToggle}>
                        <span className='sr-only'>Toggle navigation</span>
                        <span className='nav__mobile-icon'></span>
                        <span className='nav__mobile-icon'></span>
                        <span className='nav__mobile-icon'></span>
                    </button>

                    <ul className={isActive ? "nav__list" : "nav__list nav__list--active"}>
                        <li className='nav__list-item nav__list-item--main'>
                            <NavLink exact='true' activeclassname='nav__link--active' className='nav__link nav__link--main' to='/'>Mary Sparks</NavLink>
                        </li>
                        <li className='nav__list-item'>
                            <NavLink exact='true' activeclassname='nav__link--active' className='nav__link nav__link--about' to='/about'>About</NavLink>
                        </li>
                        <li className='nav__list-item'>
                            <NavLink exact='true' activeclassname='nav__link--active' className='nav__link nav__link--live' to='/live'>Live</NavLink>
                        </li>
                        <li className='nav__list-item'>
                            <NavLink exact='true' activeclassname='nav__link--active' className='nav__link nav__link--albums' to='/albums'>Albums</NavLink>
                        </li>
                        <li className='nav__list-item'>
                            <NavLink exact='true' activeclassname='nav__link--active' className='nav__link nav__link--store' to='/store'>Store</NavLink>
                        </li>
                        <li className='nav__list-item'>
                            <NavLink exact='true' activeclassname='nav__link--active' className='nav__link nav__link--contact' to='/contact'>Contact</NavLink>
                        </li>
                        <li className='nav__list-item nav__list-item--social'>
                            <a href='https://www.facebook.com/mary.sparks.315' target="_blank" rel="noreferrer" className='nav__link--social nav__link--facebook'>Facebook Link</a>
                            <a href='https://www.youtube.com/@marysparks2975' target="_blank" rel="noreferrer" className='nav__link--social nav__link--youtube'>Youtube Link</a>
                            {/* <a href='#' target="_blank" rel="noreferrer" className='nav__link--social nav__link--tiktok'>TikTok Link</a> */}
                            <a href='https://open.spotify.com/artist/359k2BY7y74tldQ9kgIEr3' target="_blank" rel="noreferrer" className='nav__link--social nav__link--spotify'>Spotify Link</a>
                            <a href='https://marysparks.bandcamp.com/music' target="_blank" rel="noreferrer" className='nav__link--social nav__link--bandcamp'>BandCamp Link</a>
                            <a href='https://soundcloud.com/user-868727553' target="_blank" rel="noreferrer" className='nav__link--social nav__link--soundcloud'>Sound Cloud Link</a>
                        </li>
                    </ul>
                </div>
            </nav>


        </header>
    )
}

export default Header